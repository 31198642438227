<wb-grid>
  <nav class="sss-header__main-nav h-hide-for-mq-lt-3">
    <ul class="h-flex-row">
      <li class="sss-header__main-nav__item">
        <a
          class="sss-header__main-nav__item__link"
          routerLinkActive="sss-header__main-nav__item__link--active"
          [routerLink]="ROUTE.DASHBOARD"
          data-qa="dashboard-link"
        >
          {{ 'common.header.navItem.dashboard' | sssTranslate | async }}
        </a>
      </li>

      <ng-container
        *ngIf="userPermissions.OUTLET_EDITOR || userPermissions.COMPANY_EDITOR || (inShopsCommerceDomain && !hasDECountryPermissions)"
      >
        <li
          *ngIf="canAccessDomain(SELLER_CENTER_DOMAIN.SETUP_PRODUCT_PARTICIPATION)"
          class="sss-header__main-nav__item"
        >
          <div class="sub-menu">
            <a
              class="sss-header__main-nav__item__link"
              routerLinkActive="sss-header__main-nav__item__link--active"
            >
              {{ 'common.header.navItem.setup_product_participation' | sssTranslate | async }}
              <wb-icon
                name="caret-down"
                class="sub-menu__icon"
              ></wb-icon>
            </a>

            <ul class="sub-menu__nav">
              <li
                class="sub-menu__nav-item"
              >
                <a
                  class="sss-header__main-nav__item__link"
                  routerLinkActive="sss-header__main-nav__item__link--active"
                  [routerLink]="ROUTE.SETUP_PRODUCT_PARTICIPATION"
                >
                  {{ 'common.header.navItem.setup_product_participation.setup' | sssTranslate | async }}
                </a>
              </li>

              <li
                *ngIf="
                  userPermissions.OUTLET_EDITOR &&
                  !inShopsCommerceDomain &&
                  canAccessDomain(SELLER_CENTER_DOMAIN.PROFILE)
                "
                class="sub-menu__nav-item"
              >
                <a
                  class="sss-header__main-nav__item__link"
                  routerLinkActive="sss-header__main-nav__item__link--active"
                  [routerLink]="PROFILE_ROUTE.OVERVIEW"
                >
                  {{ 'common.header.navItem.setup_product_participation.seller-contact' | sssTranslate | async }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li
          *ngIf="canAccessDomain(SELLER_CENTER_DOMAIN.DOCUMENTS)"
          class="sss-header__main-nav__item"
        >
          <a
            class="sss-header__main-nav__item__link"
            routerLinkActive="sss-header__main-nav__item__link--active"
            [routerLink]="DOCUMENT_ROUTE.OUTLET_UPLOAD"
            data-qa="documents-link"
          >
            {{'common.header.navItem.documents' | sssTranslate | async}}</a>
        </li>

        <li
          *ngIf="canAccessDomain(SELLER_CENTER_DOMAIN.WARRANTY_PACKAGE) && !inShopsCommerceDomain"
          class="sss-header__main-nav__item"
        >
          <div class="sub-menu">
            <a
              class="sss-header__main-nav__item__link"
              routerLinkActive="sss-header__main-nav__item__link--active"
            >
              {{ 'common.header.navItem.warrantyPackages' | sssTranslate | async }}
              <wb-icon
                name="caret-down"
                class="sub-menu__icon"
              ></wb-icon>
            </a>

            <ul class="sub-menu__nav">
              <li
                *ngIf="
                  userPermissions.OUTLET_EDITOR &&
                  !inShopsCommerceDomain &&
                  canAccessDomain(SELLER_CENTER_DOMAIN.PROFILE)
                "
                class="sub-menu__nav-item"
              >
                <a
                  class="sss-header__main-nav__item__link"
                  routerLinkActive="sss-header__main-nav__item__link--active"
                  [routerLink]="PROFILE_ROUTE.OVERVIEW"
                >
                  {{ 'common.header.navItem.warrantyPackages.email-confirmation' | sssTranslate | async }}
                </a>
              </li>

              <li
                *ngIf="canAccessDomain(SELLER_CENTER_DOMAIN.WARRANTY_PACKAGE) && !inShopsCommerceDomain"
                class="sub-menu__nav-item"
              >
                <a
                  class="sss-header__main-nav__item__link"
                  routerLinkActive="sss-header__main-nav__item__link--active"
                  [routerLink]="[DOCUMENT_ROUTE.OUTLET_UPLOAD]"
                  [queryParams]="{'product-category': 'gpme'}"
                >
                  {{ 'common.header.navItem.warrantyPackages.documents' | sssTranslate | async }}
                </a>
              </li>

              <li
                *ngIf="canAccessDomain(SELLER_CENTER_DOMAIN.WARRANTY_PACKAGE) && !inShopsCommerceDomain"
                class="sub-menu__nav-item"
              >
                <a
                  class="sss-header__main-nav__item__link"
                  routerLinkActive="sss-header__main-nav__item__link--active"
                  [routerLink]="WARRANTY_PACKAGES_ROUTE.REFERRAL_LINK"
                >
                  {{ 'common.header.navItem.warrantyPackages.referral-link' | sssTranslate | async }}
                </a>
              </li>

              <li
                *ngIf="canAccessDomain(SELLER_CENTER_DOMAIN.WARRANTY_PACKAGE) && !inShopsCommerceDomain"
                class="sub-menu__nav-item"
              >
                <a
                  class="sss-header__main-nav__item__link"
                  routerLinkActive="sss-header__main-nav__item__link--active"
                  [routerLink]="WARRANTY_PACKAGES_ROUTE.ORDERS"
                >
                  {{ 'common.header.navItem.warrantyPackages.orders' | sssTranslate | async }}
                </a>
              </li>

              <li
                *ngIf="canAccessDomain(SELLER_CENTER_DOMAIN.WARRANTY_PACKAGE) && !inShopsCommerceDomain"
                class="sub-menu__nav-item"
              >
                <a
                  class="sss-header__main-nav__item__link"
                  routerLinkActive="sss-header__main-nav__item__link--active"
                  [routerLink]="WARRANTY_PACKAGES_ROUTE.PRICING"
                >
                  {{ 'common.header.navItem.warrantyPackages.pricing' | sssTranslate | async }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li
          *ngIf="canAccessDomain(SELLER_CENTER_DOMAIN.PRICING) && inShopsCommerceDomain"
          class="sss-header__main-nav__item"
        >
          <a
            class="sss-header__main-nav__item__link"
            routerLinkActive="sss-header__main-nav__item__link--active"
            [routerLink]="ROUTE.PRICING"
            data-qa="pricing-link"
          >
            {{ 'common.header.navItem.pricing' | sssTranslate | async }}
          </a>
        </li>
      </ng-container>

      <!-- backoffice shops -->
      <ng-container *ngIf="inShopsCommerceDomain && hasDECountryPermissions">
        <li class="sss-header__main-nav__item">
          <a
            class="sss-header__main-nav__item__link"
            routerLinkActive="sss-header__main-nav__item__link--active"
            [routerLink]="'backoffice/' + BACKOFFICE_ROUTE.DOCUMENT"
            data-qa="documents-link"
          >
            {{ 'common.header.navItem.backoffice.documents' | sssTranslate | async }}
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
</wb-grid>
