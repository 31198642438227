import { ShopOutletSelectorState, SHOP_OUTLET_SELECTOR_INITIAL_STATE } from '@core/shop-outlet-selector/store/shop-outlet-selector.state';
import { Action } from '@ngrx/store';
import { DocumentState, DOCUMENT_INITIAL_STATE } from '../../document/store/document.state';
import { PricingAccessoriesState, PRICING_ACCESSORIES_INITIAL_STATE } from '../../pricing/components/accessories/store/pricing-accessories.state';
import { PricingGPmeState, PRICING_GPME_INITIAL_STATE } from '../../pricing/components/gpme/store/pricing-gpme.state';
import { AuthState, AUTH_INITIAL_STATE } from './auth/auth.state';
import { MARKETPLACE_DOCUMENT_INITIAL_STATE, MarketplaceDocumentState } from '@app/document/store/marketplace-document.state';
import { SessionState, SESSION_INITIAL_STATE } from './session/session.state';
import { CompanyOutletSelector2State, COMPANY_OUTLET_SELECTOR_2_INITIAL_STATE } from '@core/company-outlet-selector-2/store/company-outlet-selector-2.state';
import { OrderState, ORDER_INITIAL_STATE } from '@app/orders/store/orders.state';
import { GPmeOrderState, GPME_ORDER_INITIAL_STATE } from '@app/warranty-packages/components/orders/store/orders.state';
import { WarrantyPackagesState, WARRANTY_PACKAGES_INITIAL_STATE } from '@app/warranty-packages/components/gpme/store/warranty-packages.state';
import { TRANSLATION_INITIAL_STATE, TranslationState } from './translation/translation.state';

export interface RootState {
  auth: AuthState;
  shopOutletSelector: ShopOutletSelectorState;
  companyOutletSelector: CompanyOutletSelector2State;
  document: DocumentState;
  marketplaceDocument: MarketplaceDocumentState;
  gpme: PricingGPmeState;
  accessories: PricingAccessoriesState;
  session: SessionState;
  translation: TranslationState;
  order: OrderState;
  gpmeOrder: GPmeOrderState;
  warrantyPackages: WarrantyPackagesState;
}

export const ROOT_INITIAL_STATE: RootState = {
  auth: AUTH_INITIAL_STATE,
  shopOutletSelector: SHOP_OUTLET_SELECTOR_INITIAL_STATE,
  companyOutletSelector: COMPANY_OUTLET_SELECTOR_2_INITIAL_STATE,
  document: DOCUMENT_INITIAL_STATE,
  marketplaceDocument: MARKETPLACE_DOCUMENT_INITIAL_STATE,
  gpme: PRICING_GPME_INITIAL_STATE,
  accessories: PRICING_ACCESSORIES_INITIAL_STATE,
  session: SESSION_INITIAL_STATE,
  translation: TRANSLATION_INITIAL_STATE,
  order: ORDER_INITIAL_STATE,
  gpmeOrder: GPME_ORDER_INITIAL_STATE,
  warrantyPackages: WARRANTY_PACKAGES_INITIAL_STATE,
};

// eslint-disable-next-line
export interface State {
  // marker interface, intentionally empty
}

export interface ActionMap {
  [ action: string ]: any;
}

export function reduce<T extends State>(map: ActionMap, action: Action, state: T): T {
  return map[ action.type ]
    ? map[ action.type ](state, action)
    : state;
}
