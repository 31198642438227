import { SellerOrderFilteredResponse } from '@generated/gpme-order-management/models/seller-order-filtered-response';
import { Action } from '@ngrx/store';
import { OrderStatus } from '../orders.service';

export enum OrderActionTypes {
  RESET = '[Order] RESET',
  SELECT_ORDER = '[Order] SELECT_ORDER',
  UPDATE_ORDER_STATUS = '[Order] UPDATE_ORDER_STATUS',
}

export class ResetOrderAction implements Action {
  public readonly type = OrderActionTypes.RESET;
}

export class SelectOrderAction implements Action {
  public readonly type = OrderActionTypes.SELECT_ORDER;

  constructor(
    public readonly order: SellerOrderFilteredResponse,
  ) { }
}

export class UpdateOrderStatusAction implements Action {
  public readonly type = OrderActionTypes.UPDATE_ORDER_STATUS;

  constructor(
    public readonly newStatus: OrderStatus,
  ) { }
}
