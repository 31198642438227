import { ActionMap, reduce } from '@core/store/root.state';
import { Action } from '@ngrx/store';
import { OrderActionTypes, SelectOrderAction, UpdateOrderStatusAction } from './orders.actions';
import { GPmeOrderState, GPME_ORDER_INITIAL_STATE } from './orders.state';

export function gpmeOrderReducer(
  state: GPmeOrderState = GPME_ORDER_INITIAL_STATE,
  action: Action,
): GPmeOrderState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function selectOrder(state: GPmeOrderState, action: SelectOrderAction): GPmeOrderState {
  return {
    ...state,
    selectedOrder: action.order,
  };
}

function updateOrderStatus(state: GPmeOrderState, action: UpdateOrderStatusAction): GPmeOrderState {
  return {
    ...state,
    selectedOrder: {
      ...state.selectedOrder,
      status: {
        ...state.selectedOrder.status,
        // TODO: Remove usage of "sellerStatus" after BE removes it
        sellerStatus: action.newStatus,
      },
    },
  };
}

function reset(): GPmeOrderState {
  return { ...GPME_ORDER_INITIAL_STATE };
}

const REDUCER_ACTION_MAP: ActionMap = {
  [OrderActionTypes.RESET]: reset,
  [OrderActionTypes.SELECT_ORDER]: selectOrder,
  [OrderActionTypes.UPDATE_ORDER_STATUS]: updateOrderStatus,
};
