import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from '@app/app-routes.enum';
import { WarrantyPackagesRoute } from '@app/warranty-packages/warranty-packages.config';

@Component({
  selector: 'sss-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
})
export class SidebarComponent {
  public readonly Routes: typeof AppRoute = AppRoute;

  constructor(
    private readonly router: Router,
  ) {}

  get displayFeedbackButton(): boolean {
    const displayOnRoutes = [
      `${WarrantyPackagesRoute.ORDERS}`,
      `${WarrantyPackagesRoute.ORDER_DETAIL}`,
      `/${AppRoute.FEEDBACK_ORDER_MANAGEMENT}`,
    ];

    return displayOnRoutes.includes(this.router.url);
  }
}
