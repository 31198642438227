import { shopOutletSelectorReducer } from '@core/shop-outlet-selector/store/shop-outlet-selector.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { documentReducer } from '../../document/store/document.reducer';
import { marketplaceDocumentReducer } from '../../document/store/marketplace-document.reducer';
import { pricingAccessoriesReducer } from '../../pricing/components/accessories/store/pricing-accessories.reducer';
import { pricingGPMeReducer } from '../../pricing/components/gpme/store/pricing-gpme.reducer';
import { authReducer } from './auth/auth.reducer';
import { sessionReducer } from './session/session.reducer';
import { translationReducer } from './translation/translation.reducer';
import { companyOutletSelector2Reducer } from '@core/company-outlet-selector-2/store/company-outlet-selector-2.reducer';

import { RootState } from './root.state';
import { orderReducer } from '@app/orders/store/orders.reducer';
import { gpmeOrderReducer } from '@app/warranty-packages/components/orders/store/orders.reducer';
import { warrantyPackagesReducer } from '@app/warranty-packages/components/gpme/store/warranty-packages.reducer';

export const LOCAL_STORAGE_KEYS = [ 'auth', 'session' ];

export function localStorageSyncReducer(reducer: any): any {
  return localStorageSync({ keys: LOCAL_STORAGE_KEYS, rehydrate: true })(reducer);
}

export const rootReducer: ActionReducerMap<RootState> = {
  auth: authReducer,
  shopOutletSelector: shopOutletSelectorReducer,
  companyOutletSelector: companyOutletSelector2Reducer,
  document: documentReducer,
  marketplaceDocument: marketplaceDocumentReducer,
  gpme: pricingGPMeReducer,
  accessories: pricingAccessoriesReducer,
  session: sessionReducer,
  translation: translationReducer,
  order: orderReducer,
  gpmeOrder: gpmeOrderReducer,
  warrantyPackages: warrantyPackagesReducer,
};

export const metaReducers = [ localStorageSyncReducer ];
