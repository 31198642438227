import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { isBool, Value } from '@trademe/ensure';

enum TagVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

const CUSTOM_BADGE_CLASS = 'sss-badge';

@Component({
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sss-badge',
  styleUrls: [ './badge.component.scss' ],
  template: '<wb-tag [variant]="variant" #badge><ng-content></ng-content></wb-tag>',
  host: { class: 'sss-badge' },
})
export class BadgeComponent implements OnChanges {
  @ViewChild('badge', { static: true }) badge: ElementRef;

  @Input()
  public variant: TagVariant = TagVariant.PRIMARY;

  @Input()
  @Value(isBool)
  public outline = false;

  @Input()
  public customColor: string;

  @Input()
  public width: number;

  constructor(
    private renderer: Renderer2,
  ) {}

  public ngOnChanges(changes: SimpleChanges = {}): void {
    this.resetActiveClasses();

    if (this.customColor) {
      if (changes.customColor) {
        this.removeInactiveClasses(`${CUSTOM_BADGE_CLASS}--${changes.customColor.previousValue}`);
      }
      if (this.customColor.startsWith('#')) {
        this.renderer.setStyle(this.badge.nativeElement, 'background-color', this.customColor);
      } else {
        this.setActiveClass(`${CUSTOM_BADGE_CLASS}--${this.customColor}`);
      }
    }

    if (this.width) {
      this.renderer.setStyle(this.badge.nativeElement, 'width', `${this.width}px`);
    }

    if (this.outline) {
      this.setActiveClass(`${CUSTOM_BADGE_CLASS}--${this.variant}-outline`);
    }
  }

  private setActiveClass(className: string): void {
    this.renderer.addClass(this.badge.nativeElement, className);
  }

  private resetActiveClasses(): void {
    [ `${CUSTOM_BADGE_CLASS}--${this.customColor}` ]
      .forEach(className => this.removeInactiveClasses(className));
  }

  private removeInactiveClasses(className: string): void {
    this.renderer.removeClass(this.badge.nativeElement, className);
  }
}
