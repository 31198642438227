import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { CompanyManagerGuard } from '@core/guards/company-manager.guard';
import { CountryManagerGuard } from '@core/guards/country-manager.guard';
import { EntitlementGuard } from '@core/guards/entitlement.guard';
import { LegacyCountryManagerGuard } from '@core/guards/legacy-country-manager.guard';
import { SellerGuard } from '@core/guards/seller.guard';
import { AppRoute } from './app-routes.enum';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
// TODO add route and EntitlementGuard to Orders
const routes: Routes = [{
  canActivate: [ AuthGuard ],
  loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  path: AppRoute.DASHBOARD,
}, {
  loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
  path: AppRoute.HELP,
}, {
  canActivate: [ AuthGuard ],
  loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
  path: AppRoute.FEEDBACK,
}, {
  canActivate: [ AuthGuard ],
  loadChildren: () => import('./feedback-order-management/feedback-order-management.module').then(m => m.FeedbackModule),
  path: AppRoute.FEEDBACK_ORDER_MANAGEMENT,
}, {
  canActivate: [ AuthGuard, EntitlementGuard ],
  loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
  path: AppRoute.DOCUMENT,
}, {
  canActivate: [ AuthGuard ],
  loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  path: AppRoute.PROFILE,
}, {
  canActivate: [ AuthGuard, EntitlementGuard ],
  loadChildren: () => import('./shop-management/shop-management.module').then(m => m.MasterDataModule),
  path: AppRoute.MASTER_DATA,
}, {
  canActivate: [ AuthGuard, EntitlementGuard ],
  loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule),
  path: AppRoute.PRICING,
}, {
  canActivate: [ AuthGuard, EntitlementGuard ],
  loadChildren: () => import('./warranty-packages/warranty-packages.module').then(m => m.WarrantyPackagesModule),
  path: AppRoute.WARRANTY_PACKAGES,
}, {
  canActivate: [ AuthGuard, EntitlementGuard ],
  loadChildren: () => import('./shipping-costs/shipping-costs.module').then(m => m.ShippingCostsModule),
  path: AppRoute.SHIPPING_COSTS,
},
{
  canActivate: [ AuthGuard, SellerGuard ],
  loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
  path: AppRoute.ORDERS,
},
{
  canActivate: [ AuthGuard, EntitlementGuard ],
  loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule),
  path: AppRoute.SETUP_PRODUCT_PARTICIPATION,
}, {
  canActivate: [ AuthGuard, LegacyCountryManagerGuard ],
  loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule),
  path: AppRoute.BACKOFFICE,
}, {
  path: '',
  pathMatch: 'full',
  redirectTo: AppRoute.DASHBOARD,
}, {
  path: '**',
  redirectTo: AppRoute.DASHBOARD,
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true, // Asset server only supports hash strategy
    }),
  ],
  exports: [ RouterModule ],
  providers: [
    AuthGuard,
    EntitlementGuard,
    CountryManagerGuard,
    CompanyManagerGuard,
    LegacyCountryManagerGuard,
    SellerGuard,
  ],
})
export class AppRoutingModule {}
